<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <div v-if="selected_shop_item === null" class="d-flex justify-content-center mb-3">
        <memlist-spinner />
      </div>

      <div v-else>

        <!-- Add membership begin -->
        <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="6"
                :label="$t('MEMBER_RENEW.WIZARD.MEMBERSHIP_CURRENT_NAME')">

                <b-form-input
                  v-model="selected_shop_item.name"
                  :disabled="true"
                />

              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="6"
                :label="$t('MEMBER_RENEW.WIZARD.MEMBERSHIP_NEXT_NAME')"
              >
                <b-form-input
                  v-model="form.name"
                  :state="name_state"
                />
                <b-form-invalid-feedback>{{ $t('VALIDATION.REQUIRED') }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="6"
                :label="$t('MEMBER_RENEW.WIZARD.MEMBERSHIP_CURRENT_AMOUNT')"
              >
                <b-form-input
                  v-model="selected_shop_item.amount"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="6"
                :label="$t('MEMBER_RENEW.WIZARD.MEMBERSHIP_NEXT_AMOUNT')"
              >
                <b-form-input
                  v-model="form.amount"
                  :state="amount_state"
                />
                <b-form-invalid-feedback>{{ $t('VALIDATION.REQUIRED') }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <p style="margin-top: 42px;"></p>

          <b-row>
            <b-col>
              <b-button ref="save" class="save" variant="primary" type="submit">{{$t('COMMON.SAVE')}}</b-button>
              <b-button class="ml-8 cancel" variant="danger" @click="$emit('cancel')" >{{$t('COMMON.CANCEL')}}</b-button>
            </b-col>
          </b-row>
        </b-form>

      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';

import { useVuelidate } from '@vuelidate/core';
import { validate_state } from '@/core/services/validations.service';
import { required } from '@vuelidate/validators';

export default {
  name: 'RenewGuideCreateMembershipEditor',

  props: ['shop_item_id'],
  emits: ['created', 'save_data', 'cancel'],
  mixins: [ toasts ],

  components: {},

  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        amount: { required }
      }
    }
  },

  watch: {},

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    name_state() {
      return validate_state({ dirty: this.v$.form.name.$dirty, error: this.v$.form.name.$error })
    },
    amount_state() {
      return validate_state({ dirty: this.v$.form.amount.$dirty, error: this.v$.form.amount.$error })
    },
    disabledCreateButton() {
      return !this.form.name;
    }
  },

  mounted() {},

  methods: {
    async load_shop_item(shop_item_id) {
      try {
        const res = await axios.get(`/shop_item/${shop_item_id}`);

        if (res.status === 200) {
          this.selected_shop_item = res.data;
          return;
        }
      }
      catch (err) {
        console.error('load_shop_item', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_LOAD_SHOP_ITEM'));
    },

    reset_validation() {
      if (this.item) {
        this.$nextTick(() => this.v$.$reset());
      }
    },

    async validate() {
      return await this.v$.$validate();
    },

    async on_submit() {
      const valid = await this.validate();

      if (!valid) {
        this.v$.$touch()
        return
      }

      await this.create_shop_item();
    },

    async create_shop_item() {
      try {

        this.form.copy_from_shop_item_id = this.selected_shop_item.shop_item_id;
        this.form.amount_vat = 0;
        this.form.company_id = this.currentCompanyId;

        const res = await axios.post(`/shop_item`, this.form);

        if (res.status === 201) {

          this.$emit('created', res.data, this.selected_shop_item);

          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('MEMBERSHIP.CREATED'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_CREATE'));
      }

    },

    async save_data() {
      this.$emit('save_data', this.member);
    },

  },


  data() {
    return {
      form: {},

      selected_shop_item: null,

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
