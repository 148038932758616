<template>
  <div class="" id="renew-guide-wizard-membership-step">
    <b-modal
      :title="$t('MEMBERSHIP.CREATING')"
      ref="edit-form"
      hide-footer
      >

      <!-- Edit form -->
      <RenewGuideCreateMembershipEditor
        ref="membership-editor"
        :shop_item_id="selected_shop_item_id"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @created="membership_created"
        @updated="membership_updated"
      />


    </b-modal>

    <b-alert show variant="success">
      <h4 class="alert-heading">{{ $t('MEMBER_RENEW.WIZARD.MEMBERSHIP_OPTION_HEADER') }}</h4>
      <p>
        {{ $t('MEMBER_RENEW.WIZARD.MEMBERSHIP_OPTION_INFO1') }}
      </p>

      <p>
        {{ $t('MEMBER_RENEW.WIZARD.MEMBERSHIP_OPTION_INFO2') }}
      </p>

    </b-alert>

    <b-table
      id="membership-table"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="fields"
      :items="memberships"
      head-variant="light"
      ref="membership-table"
    >

    <template #cell(next_name)="row">

      <div v-if="row.item.next_shop_item_id === null">
        <b-badge variant="danger">{{ $t('MEMBER_RENEW.WIZARD.NEXT_MISSING') }}</b-badge>

      </div>
      <div v-else>
        {{ row.item.next_name }}
      </div>

    </template>

    <template #cell(actions)="row">

      <div v-if="row.item.next_shop_item_id === null">
        <div class="justify-content-end d-flex">
          <b-button
            variant="primary"
            size="sm"
            class="ml-2"
            @click.prevent="create_membership_clicked(row.item.current_shop_item_id)"
            >{{$t('MEMBER_RENEW.WIZARD.CREATE_NEXT')}}</b-button>

        </div>
      </div>

    </template>

  </b-table>

  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-3.scss';

:deep .alert.alert-success {
  background-color: #d1f0ff;
  border-color: #d1f0ff;
  color: #1d3d6f;
}

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import RenewGuideCreateMembershipEditor from './RenewGuideCreateMembershipEditor.vue';

export default {
  name: 'renew-guide-wizard-membership-step',
  components: {
    RenewGuideCreateMembershipEditor,
  },
  props: ['options','name'],
  emits: ['on_check_validity'],
  mixins: [ toasts ],
  computed: {
    is_infinite_period() {
      return this.periods.length === 0;
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods']),
    is_valid() {

      return true;
    },

  },
  mounted: function() {
    this.$nextTick(()=>{
      this.check_validity();

      this.load_memberships();
    });
  },
  data() {
    return {

      all_selected: false,

      selected_shop_item_id: null,

      memberships: [],

      fields: [

        {
          key: 'current_name',
          label: this.$t('MEMBER_RENEW.WIZARD.MEMBERSHIP_CURRENT_NAME'),
          sortable: true,
          sortByFormatted: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },

        {
          key: 'next_name',
          label: this.$t('MEMBER_RENEW.WIZARD.MEMBERSHIP_NEXT_NAME'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },

        {
          key: 'company_name',
          label: this.$t('MEMBER_RENEW.WIZARD.MEMBERSHIP_COMPANY_NAME'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
        }
      ]

    };
  },
  watch: {

    showNext(newVal, oldVal) {

    }
  },
  methods: {

    membership_updated(shop_item) {},

    membership_created(next_shop_item, current_shop_item) {
      const index = this.memberships.findIndex((membership) => {
        return membership.current_shop_item_id === current_shop_item.shop_item_id;
      });

      if (index !== -1) {
        this.memberships[index].next_shop_item_id = next_shop_item.shop_item_id;
        this.memberships[index].next_name = next_shop_item.name;
      }

      this.memberships = [...this.memberships];

    },

    create_membership_clicked(shop_item_id) {
      this.selected_shop_item_id = shop_item_id;

      console.log('selected shop item', this.selected_shop_item_id);

      this.$refs['edit-form'].show();

      this.$nextTick(()=>{
        this.$refs['membership-editor'].load_shop_item(this.selected_shop_item_id);
      });
    },

    async load_memberships() {
      try {
        const res = await axios.get(`/member_renew/memberships/company/${this.currentCompanyId}/period/${this.currentPeriodId}`);

        if (res.status === 200) {
          this.memberships = res.data;
          return;
        }
      }
      catch (err) {
        console.error(err);
      }
    },

    check_validity() {
      this.$emit('on_check_validity', this.name, this.is_valid, this.options);
    },

    on_submit_email_form() {},

  }
};
</script>
